#ernotfound {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#ernotfounduser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ernotfound {
  color: red;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  text-transform: uppercase;
}
.ernotfound h1 {
  font-size: 7rem;
  margin: 0;
}

.ernotfound Link {
  font-size: 4rem;
  margin: 0;
}

.ersection-heading {
  text-align: center;
  font-size: 1.5rem;
}

.ercontainer {
  display: flex;
  gap: 1;
  justify-content: center;
  margin: 0 auto;
}

.erprofile {
  position: relative;
  transition: all 0.5s;
}

.erprofile:hover {
  transform: translateY(20px);
}

.erprofile img {
  height: 50;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.erprofile .ername {
  position: absolute;
  background-color: #facc15;
  font-family: "Bebas Neue", cursive;
  padding: 15px 30px;
  border-radius: 100px;
  bottom: -80px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-40%, -20px);
  letter-spacing: 1px;
  font-size: 20px;
  opacity: 0;
  transition: all 0.3s;
}

.erprofile .ername::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #facc15;
  top: 0;
  left: 50%;
  transform: translate(-40%, -20%) rotate(45deg);
}

.erprofile img:hover + .ername {
  opacity: 1;
  transform: translateX(-50%);
  box-shadow: 0 10px 20px rgba(86, 86, 198, 0.3);
}

.erbtn {
  margin-top: 2rem;
  background-color: #facc15;
  background-image: linear-gradient(-180deg, #facc15, #dab10c);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial,
    sans-serif;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  width: 14rem;
  z-index: 9;
  border: 0;
  transition: box-shadow 0.2s;
}

.erbtn:hover {
  box-shadow: #facc15;
}
